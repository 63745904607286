/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAtom } from 'jotai/index';
import { useCallback, useEffect, useState } from 'react';

import { getPublicEnv } from '../../../environment/index';
import { useLogger } from '../../logging/hook/useLogger';
import { customerioStateAtom } from '../atoms/customerioState.atom';
import { serializeRawCustomerioMessageAction } from '../serializers/serializeRawCustomerioMessageAction';

import { useCustomerIoMessageAction } from './useCustomerIoMessageAction';

type CustomerioOperation = (cio: any) => void;

export function useCustomerio() {
  const [customerioState, setCustomerioState] = useAtom(customerioStateAtom);
  const { logger } = useLogger();
  const [operationsQueue, setOperationsQueue] = useState<CustomerioOperation[]>([]);
  const { execute } = useCustomerIoMessageAction();

  const addToQueue = useCallback(
    (operation: CustomerioOperation) => {
      if (customerioState.status !== 'ready') {
        setOperationsQueue((prev) => [...prev, operation]);
      } else {
        operation((window as any)._cio);
      }
    },
    [customerioState, setOperationsQueue],
  );

  const onMessageOpened = useCallback(
    (event: any) => {
      logger.info(
        `Customerio in-app message opened (type:, ${event.type}, id: ${event.detail.messageId}, delivery id: ${event.detail.deliveryId})`,
      );
    },
    [logger],
  );

  const onMessageAction = useCallback(
    (event: any) => {
      const customerIoMessageAction = serializeRawCustomerioMessageAction(event);
      if (customerIoMessageAction) {
        logger.info(`Customerio in-app message action`, event, customerIoMessageAction);
        execute(customerIoMessageAction);
      }
      event.detail.message.dismiss();
    },
    [execute, logger],
  );

  const onMessageError = useCallback(
    (event: any) => {
      logger.info(`Customerio in-app message  error`, event);
    },
    [logger],
  );

  const customerioDidLoad = useCallback(
    (cio: any) => {
      logger.info(`Customerio did load, will run ${operationsQueue.length} operations`);

      cio.on('in-app:message-opened', onMessageOpened);
      cio.on('in-app:message-action', onMessageAction);
      cio.on('in-app:message-error', onMessageError);

      operationsQueue.forEach((operation) => operation(cio));
      setOperationsQueue([]);

      setCustomerioState({ status: 'ready' });
    },
    [logger, onMessageAction, onMessageError, onMessageOpened, operationsQueue, setCustomerioState],
  );

  // run the listener everytime message is shown

  const initCustomerio = useCallback(() => {
    if (customerioState.status !== 'idle' || document.getElementById('cio-tracker') !== null) {
      return;
    }

    const customerIoSiteId = getPublicEnv('PUBLIC_CUSTOMERIO_SITE_ID');

    if (!customerIoSiteId || customerIoSiteId === 'null') {
      logger.info(`Skipping Customerio initialization (site id: ${customerIoSiteId})`);
      return;
    }

    setCustomerioState({ status: 'loading' });

    logger.info(`Initializing Customerio with site id: ${customerIoSiteId}`);

    if (!customerIoSiteId) {
      logger.error('Missing "PUBLIC_CUSTOMERIO_SITE_ID" environment variable.');
      setCustomerioState({
        status: 'error',
        errorMessage: 'Missing "PUBLIC_CUSTOMERIO_SITE_ID" environment variable.',
      });
      return;
    }

    const _cio = (window as any)._cio || [];
    (window as any)._cio = _cio;

    let c: number;

    const a: (f: string) => (...args: any[]) => void = function (f) {
      return function (...args: any[]) {
        _cio.push([f].concat(args));
      };
    };

    const b: string[] = ['load', 'identify', 'sidentify', 'track', 'page', 'on', 'off'];

    for (c = 0; c < b.length; c++) {
      _cio[b[c]] = a(b[c]);
    }

    const t = document.createElement('script'),
      s = document.getElementsByTagName('script')[0];
    t.async = true;
    t.id = 'cio-tracker';
    t.setAttribute('data-site-id', customerIoSiteId);
    t.setAttribute('data-use-in-app', 'true');
    t.src = 'https://assets.customer.io/assets/track.js';
    s.parentNode?.insertBefore(t, s);

    const intervalId = setInterval(async () => {
      const _cio: undefined | [] = (window as any)._cio;
      if (_cio !== undefined && Object.keys(_cio).length > 0) {
        clearInterval(intervalId);
        setCustomerioState({ status: 'ready' });
        customerioDidLoad(_cio);
      }
    }, 100);
  }, [customerioDidLoad, customerioState.status, logger, setCustomerioState]);

  useEffect(() => {
    initCustomerio();
  }, [initCustomerio]);

  const identify = useCallback(
    (id: string | null, data: Record<string, any>) => {
      addToQueue((cio) => {
        cio.identify({ ...data, id: id });
      });
    },
    [addToQueue],
  );

  const track = useCallback(
    (event: string, data: Record<string, any>) => {
      addToQueue((cio) => {
        cio.track(event, data);
      });
    },
    [addToQueue],
  );

  const page = useCallback(
    (page: string) => {
      addToQueue((cio) => {
        cio.page(page);
      });
    },
    [addToQueue],
  );

  return {
    identify,
    track,
    page,
  } as const;
}
